import { navigateWithQueryString } from "../utils/browser"

export const onFullfilled = (response) => Promise.resolve(response)

const showToast = (content) => {
  const event = new CustomEvent("showToast", content)
  return window.dispatchEvent(event)
}

export const onRejected = (error) => {
  const errorCategory = error?.response.data?.error_category
  const message = error?.response?.data?.message

  const actionPerCategory = {
    Info: () =>
      showToast({
        detail: {
          message,
        },
      }),
    Warning: () =>
      showToast({
        detail: {
          message,
        },
      }),
    Error: () => {
      navigateWithQueryString("/")
      showToast() // ao executar o toast sem enviar uma mensagem, ele irá exibir a mensagem padrão de erro.
    },
  }

  const action = actionPerCategory[errorCategory]
  action && action()

  return Promise.reject(error)
}
