export const loaderTexts = [
  <>
    <strong>aguarde...</strong>
    {" estamos buscando a melhor oferta para você ;)"}
  </>,
  <>
    {"estamos "}
    <strong>analisando</strong>
    {" suas condições de crédito"}
  </>,
  <>
    {"separando a "}
    <strong>condição ideal</strong>
    {" para o seu perfil"}
  </>,
  <>
    <strong>pronto,</strong>
    {" encontramos a melhor oportunidade para você!"}
  </>,
]
