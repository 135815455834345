const Lead = ({
  documentNumber = String(),
  email = String(),
  fullName = String(),
  phone = String(),
}) => ({
  documentNumber,
  email,
  fullName,
  phone,
})

const LeadFormatted = (payload) => {
  const lead = {
    email: payload.email,
    fullName: payload.fullName.replace(/\s+/g, " ").trim(),
    documentNumber: payload.documentNumber.replace(/\D/g, ""),
    phone: payload.phone.replace(/\D/g, ""),
  }

  return lead
}

export { Lead, LeadFormatted }
