// Services
import { getAuth } from "services/auth"

// Utils
import { currencyToFloat } from "@mobi/utils/formatters/currency"
import recaptcha from "utils/recaptcha"
import entities from "@entities"

const stepName = "simulation"
const updateStepName = "simulation-update"
const dealStepName = "simulation-deal"

const getSimulation = async (isDealStep = false) => {
  const http = await getAuth({ auth: true })
  const response = await http.get(
    `/form/${isDealStep ? dealStepName : stepName}/summary`
  )

  if (response?.status === 200) {
    const payload = response.data

    const mountSimulationOptions = {
      ...payload.simulation,
      simulationInstallments: payload.simulationInstallments,
    }
    const simulationOptions = entities.SimulationOptions(mountSimulationOptions)
    const vehicleDealProperties = entities.VehicleDealProperties(
      payload.vehicleDealProperties
    )
    const seller = entities.Seller(payload.seller)
    const lead = entities.Lead(payload.lead)

    const formattedPayload = {
      isAbleToProceed: payload.isAbleToProceed,
      ...vehicleDealProperties,
      ...simulationOptions,
      ...seller,
      ...lead,
    }

    return Promise.resolve({ ...payload, ...formattedPayload })
  } else {
    Promise.reject(response)
  }
}

const update = async (payload = {}, analytics, isUpdate) => {
  const http = await getAuth({ auth: true })
  const formattedPayload = {
    simulation: {
      installments: payload?.downPayment
        ? parseInt(payload?.numberOfInstallments)
        : undefined,
      downPayment: payload?.downPayment
        ? currencyToFloat(payload?.downPayment)
        : undefined,
      financeProtectionInsurance: payload?.financeProtectionInsurance,
    },
  }

  const response = await http.put("/form", {
    analytics,
    currentFormStep: isUpdate ? updateStepName : stepName,
    ...formattedPayload,
  })

  if (response?.status === 200) {
    return response
  }
  throw response
}

const sendSimulationByEmail = () => {
  return new Promise((resolve, reject) => {
    recaptcha
      .execute()
      .then(async (token) => {
        const http = await getAuth({ auth: true, recaptcha: token })
        resolve(http.post("/simulation/send/", {}))
      })
      .catch((err) => reject(err))
  })
}

const sendFeedback = (payload) => {
  return new Promise((resolve, reject) => {
    recaptcha
      .execute()
      .then(async (token) => {
        const http = await getAuth({ auth: true, recaptcha: token })
        resolve(
          http.post("/simulation/feedback/", {
            leadFeedback: {
              rating: payload.rating,
              improvements: payload.improvements,
              description: payload.description,
            },
          })
        )
      })
      .catch((err) => reject(err))
  })
}

const simulationService = {
  update,
  sendSimulationByEmail,
  sendFeedback,
  getSimulation,
}

export default simulationService
