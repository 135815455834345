import axios from "axios"
import { API } from "gatsby-env-variables"

import { onFullfilled, onRejected } from "services/interceptors"
import { getAuth } from "services/auth"

//Entities
import entities from "@entities"

const http = axios.create({ baseURL: API.BASE_URL })
http.interceptors.response.use(onFullfilled, onRejected)

const stepName = "vehicle-purchase-period"

const getSimulation = async () => {
  const http = await getAuth({ auth: true })
  const response = await http.get(`/form/${stepName}/summary`)

  if (response?.status === 200) {
    return Promise.resolve({ ...response.data })
  } else {
    Promise.reject(response)
  }
}

const update = async (payload, analytics) => {
  const http = await getAuth({ auth: true })
  const vehicleDealProperties = entities.VehiclePurchasePeriodFormatted(payload)

  const response = await http.put("/form", {
    analytics,
    currentFormStep: stepName,
    vehicleDealProperties,
  })

  if (response?.status === 200) {
    return response
  }
  throw response
}

export default {
  update,
  getSimulation,
}
