import { useEffect, useState } from "react"
import classNames from "classnames"
import { FocusOn } from "react-focus-on"

// Components
import { Text } from "@mobi/ds"

// Assets
import Logo from "images/icarros-logo.svg"

// Styles
import "./index.scss"

// Data
import { loaderTexts } from "./dataContent"

/**
 * @property {"text"|"spinner-only"} variant A variante do componente. Pode ser "text" ou "spinner".
 */
const Loader = ({ variant = "text", canBlockFocus = true }) => {
  const [activedText, setActivedText] = useState(0)

  useEffect(() => {
    const sliderTimer = setInterval(() => {
      setActivedText((counter) =>
        counter < loaderTexts.length - 1 ? counter + 1 : 0
      )
    }, 5000)

    return () => {
      clearInterval(sliderTimer)
    }
  }, [])

  return (
    <FocusOn enabled={canBlockFocus}>
      <div
        className={classNames("loader-screen", {
          "loader-screen--spinner-only": variant === "spinner-only",
        })}
      >
        <div className="loader-screen__container">
          <Logo className="loader-screen__logo" />
          <div className="loader-screen__content">
            <div className="loader-screen__loader">
              <svg viewBox="25 25 50 50">
                <circle
                  className="circle"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
            {variant === "text" && (
              <div className="loader-screen__wrapper-text">
                {loaderTexts.map((item, index) => {
                  return (
                    <Text
                      key={index}
                      as="h3"
                      size="xl"
                      weight="400"
                      className={classNames("loader-screen__slider-text", {
                        "loader-screen__slider-text--actived":
                          index === activedText,
                      })}
                    >
                      {item}
                    </Text>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </FocusOn>
  )
}

export default Loader
