const Address = ({
  streetName = String(),
  number = String(),
  complement = String(),
  neighborhood = String(),
  city = String(),
  state = String(),
  postalCode = String(),
}) => ({
  streetName,
  number,
  complement,
  neighborhood,
  city,
  state,
  postalCode,
})

export default Address
