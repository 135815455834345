import Seller from "./Seller"
import Address from "./Address"
import Phone from "./Phone"
import {
  VehicleDealProperties,
  VehicleDealPropertiesFormatted,
} from "./VehicleDealProperties"
import { VehiclePurchasePeriodFormatted } from "./VehiclePurchasePeriod"
import SimulationOptions from "./SimulationOptions"
import { Lead, LeadFormatted } from "./Lead"

export default {
  Seller,
  Address,
  Phone,
  VehicleDealProperties,
  VehicleDealPropertiesFormatted,
  VehiclePurchasePeriodFormatted,
  SimulationOptions,
  Lead,
  LeadFormatted,
}
