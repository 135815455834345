const VehicleDealProperties = ({
  brandId = Number(),
  brandName = String(),
  modelId = Number(),
  modelName = String(),
  dealCity = String(),
  dealState = String(),
  purchasePeriod = String(),
  vehicleId = Number(),
  initialPrice = Number(),
  finalPrice = Number(),
  vehicleValue = Number(),
  initialYear = new Date().getFullYear(),
  finalYear = new Date().getFullYear(),
}) => {
  return {
    brandId,
    brandName,
    modelId,
    modelName,
    dealCity,
    dealState,
    purchasePeriod,
    vehicleId,
    vehicleValue,
    initialPrice,
    finalPrice,
    initialYear: parseInt(initialYear),
    finalYear: parseInt(finalYear),
  }
}

const VehicleDealPropertiesFormatted = (payload) => {
  const vehicleDealProperties = {
    brandId: payload.brand?.value,
    brandName: payload.brand?.label,
    modelId: payload.model?.value,
    modelName: payload.model?.label,
    purchasePeriod: payload.purchasePeriod,
    hasVisited: payload.hasVisited,
    isNewVehicle: false,
    vehicleYear: payload.initialYear,
    vehicleValue: payload.finalPrice,
    dealCity: payload.dealCity,
    dealState: payload.dealState,
  }

  return vehicleDealProperties
}

export { VehicleDealProperties, VehicleDealPropertiesFormatted }
