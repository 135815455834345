import numberFormatter from "@mobi/utils/formatters/number"

const InstallmentOption = ({
  installment = Number(),
  amount = Number(),
  iofValue = Number(),
  monthlyInterest = Number(),
  annualInterest = Number(),
  monthlyEffectiveInterest = Number(),
  annualEffectiveInterest = Number(),
  highChanceOfApproval = Boolean(),
  suggested = Boolean(),
  selected = Boolean(),
  financeInsuranceInstallmentValue = Number(),
}) => ({
  installment,
  amount,
  iofValue: numberFormatter({ value: iofValue }),
  monthlyInterest: monthlyInterest.toFixed(2).replace(".", ","),
  annualInterest: annualInterest.toFixed(2).replace(".", ","),
  monthlyEffectiveInterest: monthlyEffectiveInterest
    .toFixed(2)
    .replace(".", ","),
  annualEffectiveInterest: annualEffectiveInterest.toFixed(2).replace(".", ","),
  highChanceOfApproval,
  suggested,
  selected,
  financeInsuranceInstallmentValue: numberFormatter({
    value: financeInsuranceInstallmentValue,
  }),
})

export default InstallmentOption
