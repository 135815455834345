import axios from "axios"
import { API } from "gatsby-env-variables"

import { onFullfilled, onRejected } from "services/interceptors"
import { getAuth } from "services/auth"

// Utils
import getQueryStringFromObject from "utils/getQueryStringFromObject"

// Mappers
import {
  toVehiclesList,
  toVehiclesYears,
  toVehiclesBrands,
  toVehiclesModels,
} from "services/mappers"

//Entities
import entities from "@entities"

const http = axios.create({ baseURL: API.BASE_URL })
http.interceptors.response.use(onFullfilled, onRejected)

const stepName = "vehicle-deal-properties"

const getSimulation = async () => {
  const http = await getAuth({ auth: true })
  const response = await http.get(`/form/${stepName}/summary`)

  if (response?.status === 200) {
    return Promise.resolve({ ...response.data })
  } else {
    Promise.reject(response)
  }
}

const update = async (payload, analytics) => {
  const http = await getAuth({ auth: true })
  const vehicleDealProperties = entities.VehicleDealPropertiesFormatted(payload)

  const response = await http.put("/form", {
    analytics,
    currentFormStep: stepName,
    vehicleDealProperties,
  })

  if (response?.status === 200) {
    return response
  }
  throw response
}

const getVehicles = async (filters = {}) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(
    `/vehicles/search${getQueryStringFromObject(filters)}`
  )

  if (response?.status === 200) {
    const { vehicles } = response.data
    return toVehiclesList(vehicles)
  }

  throw response
}

const getYears = async (filters = {}) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(
    `/vehicles/years${getQueryStringFromObject(filters)}`
  )

  if (response?.status === 200) {
    const { financingYears } = response.data
    return toVehiclesYears(financingYears)
  }

  throw response
}

const getBrands = async (filters = {}) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(
    `/vehicles/search/brands${getQueryStringFromObject(filters)}`
  )

  if (response?.status === 200) {
    return toVehiclesBrands(response.data)
  }

  throw response
}

const getModels = async ({ brand, ...filters } = {}) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(
    `/vehicles/search/brands/${brand}/models${getQueryStringFromObject(
      filters
    )}`
  )

  if (response?.status === 200) {
    return toVehiclesModels(response.data)
  }

  throw response
}

export default {
  getSimulation,
  update,
  getVehicles,
  getYears,
  getBrands,
  getModels,
}
