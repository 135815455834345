// Services
import { getAuth } from "services/auth"

// Utils
import entities from "@entities"
import getQueryStringFromObject from "utils/getQueryStringFromObject"

const stepName = "deal"

const getSimulation = async () => {
  const http = await getAuth({ auth: true })

  const response = await http.get(`/form/${stepName}/summary`)
  if (response?.status === 200) {
    const payload = response.data

    const mountSimulationOptions = {
      ...payload.simulation,
      simulationInstallments: payload.simulationInstallments,
    }
    const simulationOptions = entities.SimulationOptions(mountSimulationOptions)
    const vehicleDealProperties = entities.VehicleDealProperties(
      payload.vehicleDealProperties
    )
    const seller = entities.Seller(payload.seller)
    const lead = entities.Lead(payload.lead)

    const formattedPayload = {
      isAbleToProceed: payload.isAbleToProceed,
      ...vehicleDealProperties,
      ...simulationOptions,
      ...seller,
      ...lead,
    }

    return Promise.resolve({ ...payload, ...formattedPayload })
  } else {
    Promise.reject(response)
  }
}

const update = async (payload = {}, analytics) => {
  const http = await getAuth({ auth: true })

  const formattedPayload = {
    deal: {
      dealID: payload?.dealID,
    },
  }

  const response = await http.put("/form", {
    analytics,
    currentFormStep: stepName,
    ...formattedPayload,
  })

  if (response?.status === 200) {
    return response
  }

  throw response
}

const retry = async (payload = {}, analytics) => {
  const http = await getAuth({ auth: true })

  const response = await http.put("/form", {
    analytics,
    currentFormStep: "simulation-deal",
    simulationDeal: {
      dealID: payload?.dealID,
    },
  })

  return response
}

const getDeals = async (filters) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(`/deals/${getQueryStringFromObject(filters)}`)

  if (response?.status === 200) {
    return response?.data
  }

  throw response
}

const deal = { getSimulation, update, getDeals, retry }

export default deal
