// Services
import { getAuth } from "services/auth"

//Entities
import entities from "@entities"

const stepName = "personal-data"

const createSimulation = async (payload, analytics, token = "") => {
  const http = await getAuth({ auth: true, recaptcha: token })
  const lead = entities.LeadFormatted(payload)

  return http.post("/form", {
    currentFormStep: stepName,
    analytics,
    lead,
  })
}

const getSimulation = async () => {
  const http = await getAuth({ auth: true })
  const response = await http.get(`/form/${stepName}/summary`)

  if (response?.status === 200) {
    return Promise.resolve({ ...response.data })
  } else {
    Promise.reject(response)
  }
}

const update = async (payload, config, analytics) => {
  const { uuid } = config

  const http = await getAuth({ auth: true })
  const lead = entities.LeadFormatted(payload)

  const response = await http.put("/form", {
    analytics,
    currentFormStep: stepName,
    [uuid?.name]: uuid?.value,
    lead,
  })

  if (response?.status === 200) {
    return response
  }

  throw response
}

const personalData = { createSimulation, getSimulation, update }

export default personalData
