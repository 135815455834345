import InstallmentOption from "./InstallmentOption"
import numberFormatter from "@mobi/utils/formatters/number"

const SimulationOptions = ({
  downPayment = Number(),
  installments = Number(),
  contractRegistrationFee = Number(),
  defaultDownPayment = Number(),
  highChanceOfApprovalDownPayment = Number(),
  propertyValuationFee = Number(),
  minAcceptableDownPayment = Number(),
  totalAmountFinancedWithoutTax = Number(),
  totalAmountFinanced = Number(),
  totalAmountPayable = Number(),
  simulationInstallments = Array([]),
  financeProtectionInsurance = Boolean(),
}) => ({
  defaultDownPaymentRaw: defaultDownPayment,
  defaultDownPayment: new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(defaultDownPayment),
  minAcceptableDownPayment,
  availableInstallmentOptions: simulationInstallments.map((item) =>
    InstallmentOption(item)
  ),
  propertyValuationFee: numberFormatter({ value: propertyValuationFee }),
  contractRegistrationFee: numberFormatter({
    value: contractRegistrationFee,
  }),
  totalAmountFinancedWithoutTax,
  totalAmountFinanced: numberFormatter({ value: totalAmountFinanced }),
  totalPayable: numberFormatter({ value: totalAmountPayable }),
  downPayment,
  installments,
  highChanceOfApprovalDownPayment,
  financeProtectionInsurance,
})

export default SimulationOptions
