import axios from "axios"
import { API } from "gatsby-env-variables"
import { onFullfilled, onRejected } from "services/interceptors"

// Services
import personalData from "services/personalData"
import simulation from "services/simulation"
import vehicles from "services/vehicles"
import vehiclePurchasePeriod from "services/vehiclePurchasePeriod"
import places from "services/places"
import deal from "services/deal"
import verificationCode from "services/verificationCode"
import recovery from "services/recovery"

const createHtppInstance = () => {
  const _http = axios.create({ baseURL: `${API.BASE_URL}` })
  _http.interceptors.response.use(onFullfilled, onRejected)
  return _http
}

const services = {
  http: createHtppInstance(),
  personalData,
  simulation,
  vehicles,
  vehiclePurchasePeriod,
  places,
  deal,
  verificationCode,
  recovery,
}
export default services
