import axios from "axios"
import { API } from "gatsby-env-variables"

import { onFullfilled, onRejected } from "services/interceptors"
import { getAuth } from "services/auth"

// Utils
import getQueryStringFromObject from "utils/getQueryStringFromObject"

// Mappers
import { toCitiesList } from "services/mappers"

const http = axios.create({ baseURL: API.BASE_URL })
http.interceptors.response.use(onFullfilled, onRejected)

const getCities = async (filters = {}) => {
  const http = await getAuth({ auth: true })

  const response = await http.get(
    `/deals/cities/${getQueryStringFromObject(filters)}`
  )
  if (response.status === 200) {
    return toCitiesList(response.data)
  } else {
    throw response
  }
}

export default { getCities }
