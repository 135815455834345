import numberFormatter from "@mobi/utils/formatters/number"

const toCitiesList = (list = []) => {
  return list
    ? list.map(({ displayName, city, state }) => {
        return {
          label: displayName,
          value: city,
          state,
        }
      })
    : []
}

const toVehiclesList = (list = []) => {
  return list
    ? list.map(({ id, brandModelVersion }) => {
        return {
          value: brandModelVersion,
          label: brandModelVersion,
          id,
        }
      })
    : []
}

const toVehiclesYears = (list = []) => {
  return list.map(({ vehicleManufactureYear, newVehicleEnabled }) => {
    return {
      value: vehicleManufactureYear,
      label: vehicleManufactureYear,
      hasnewvehiclesavailable: newVehicleEnabled,
    }
  })
}

const toVehiclesBrands = (list = []) => {
  return list.map(({ id, name, brandLogo }) => {
    return {
      value: id,
      label: name,
      icon: brandLogo,
    }
  })
}

const toVehiclesModels = (list = []) => {
  return list.map(({ id, name }) => {
    return {
      value: id,
      label: name,
    }
  })
}

const toInstallmentsList = (list = []) => {
  const formattedList = list.map(
    ({
      installment,
      amount,
      suggested,
      highChanceOfApproval,
      iofValue,
      ...payload
    }) => {
      const label = `${installment}x ${numberFormatter({
        value: amount,
      })}`

      return {
        ...payload,
        value: installment,
        label: label,
        suggested,
        amount,
        iofValue,
      }
    }
  )

  const orderedList = formattedList.sort((current) => {
    if (current.suggested) return -1
    return 0
  })

  return orderedList
}

export {
  toCitiesList,
  toVehiclesList,
  toVehiclesYears,
  toVehiclesBrands,
  toVehiclesModels,
  toInstallmentsList,
}
