// Services
import { getAuth } from "services/auth"

const generationStepName = "verification-code-generation"
const confirmationStepName = "verification-code-confirmation"

const getSimulation = async () => {
  const http = await getAuth({ auth: true })
  const response = await http.get(`/form/${generationStepName}/summary`)

  if (response?.status === 200) {
    return response.data
  }

  throw response
}

const updateConfirmation = async (payload = {}, analytics, uuid) => {
  const http = await getAuth({ auth: true })

  const formattedPayload = { verificationCode: payload }

  const response = await http.put("/form", {
    analytics,
    currentFormStep: confirmationStepName,
    [uuid?.name]: uuid?.value,
    ...formattedPayload,
  })

  if (response?.status === 200) {
    return response
  } else {
    throw response
  }
}

const updateGeneration = async (payload = {}, analytics, uuid) => {
  const http = await getAuth({ auth: true })
  const formattedPayload = { verificationCode: payload }

  const response = await http.put("/form", {
    analytics,
    currentFormStep: generationStepName,
    [uuid?.name]: uuid?.value,
    ...formattedPayload,
  })

  if (response?.status === 200) {
    return response
  }

  throw response
}

const verificationCode = { getSimulation, updateConfirmation, updateGeneration }

export default verificationCode
