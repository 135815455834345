import { RECAPTCHA } from "gatsby-env-variables"
import addScript from "@mobi/utils/browser/addScript"

const load = () => {
  return addScript(
    `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA}&invisible=true`
  )
}

const execute = () => {
  const grecaptcha = window.grecaptcha

  return new Promise((resolve, reject) => {
    if (!grecaptcha) reject()

    grecaptcha.ready(() => {
      grecaptcha
        .execute(RECAPTCHA, { action: "submit" })
        .then((token) => {
          resolve(token)
        })
        .catch((err) => {
          reject(err)
        })
    })
  })
}

export default { load, execute }
