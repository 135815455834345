import Phone from "./Phone"

const Seller = ({
  displayName = String(),
  sellerUUID = String(),
  phone = String(),
  searchByNeighborhood = "nao",
  streetName = String(),
  number = String(),
  complement = String(),
  neighborhood = String(),
  city = String(),
  state = String(),
  postalCode = String(),
  isSellerSelected = null,
}) => {
  return {
    displayName,
    sellerUUID,
    phone: Phone(phone),
    isSellerSelected,
    label: displayName,
    id: sellerUUID,
    searchByNeighborhood,
    streetName,
    number,
    complement,
    neighborhood,
    city,
    state,
    postalCode,
  }
}

export default Seller
