// Services
import { getAuth } from "services/auth"

// Utils
import getQueryStringFromObject from "utils/getQueryStringFromObject"

const recoverSimulation = async ({ analytics, ...params }) => {
  const http = await getAuth({ auth: true, isExpired: true })
  const response = await http.post(
    `/form/recovery/${getQueryStringFromObject(params)}`,
    {
      analytics,
    }
  )

  if (response?.status === 200) {
    return response
  } else {
    throw response
  }
}

const recovery = { recoverSimulation }

export default recovery
