import services from "services"
import { APP_NAME } from "gatsby-env-variables"
import { storage } from "utils/storage"

import { API } from "gatsby-env-variables"

const key = "auth"

const _getToken = ({ key, isExpired = false }) => {
  let tokenSaved = ""
  let formattedToken = ""
  try {
    tokenSaved = storage.session.getItem(key)
    formattedToken = JSON.parse(tokenSaved)
      ? JSON.parse(tokenSaved)?.token
      : null
  } catch (err) {
    storage.session.removeItem(key)
    console.log(err)
  }

  if (formattedToken && !isExpired) {
    return new Promise((resolve) => {
      resolve({ data: { token: formattedToken } })
    })
  } else {
    return services.http.get("/authorization", {
      headers: {
        App: API.KEY,
        partner: APP_NAME,
      },
    })
  }
}

const _saveToken = (props) => {
  const { key } = props
  return new Promise((resolve) => {
    const isTokenSaved = JSON.parse(storage.session.getItem(key))?.token
    if (isTokenSaved) {
      resolve(props)
    } else {
      storage.session.setItem(key, JSON.stringify(props))
      resolve(props)
    }
  })
}

const _configRequestHeaders = (authProps) => {
  const { token, recaptcha } = authProps
  const tokenFormatted = `Bearer ${token}`
  services.http.defaults.headers.common["partner"] = APP_NAME
  services.http.defaults.headers.get["Token"] = tokenFormatted
  services.http.defaults.headers.post["Token"] = tokenFormatted
  services.http.defaults.headers.put["Token"] = tokenFormatted

  if (recaptcha) {
    services.http.defaults.headers.post["g-recaptcha-response"] = recaptcha
    services.http.defaults.headers.put["g-recaptcha-response"] = recaptcha
  }
  return services.http
}

const getAuth = ({ auth = false, ...options }) => {
  if (auth) {
    return Promise.resolve()
      .then(() => _getToken({ key, ...options }))
      .then(({ data }) => _saveToken({ ...options, ...data, key }))
      .then((data) => _configRequestHeaders({ ...options, ...data }))
  }

  return Promise.resolve(services.http)
}

const updateToken = (authProps) => {
  const hasAuthProps = authProps && Object.keys(authProps).length > 0
  if (hasAuthProps) {
    storage.session.setItem(key, JSON.stringify(authProps))
    _configRequestHeaders(authProps)
  }
}

const clearToken = () => {
  storage.session.removeItem(key)
}

export { getAuth, updateToken, clearToken }
